<template>
  <DetailTemplate
    :custom-class="'quotation-detail detail-page'"
    v-if="getPermission('quotation:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex align-center" v-if="pageLoaded">
        <span class="color-custom-blue font-weight-700 font-size-20">{{
          detail.job_title
        }}</span>
        <CustomStatus
          :status="detail.status"
          endpoint="quotation/status"
        ></CustomStatus>
        <template v-if="detail.is_revised">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="red lighten-4"
                class="ml-0 mr-2 black--text font-weight-600"
                label
              >
                REVISED
              </v-chip>
            </template>
            <span>Quotation is Revised</span>
          </v-tooltip>
        </template>
        <template v-if="detail.is_contract">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="green lighten-1"
                class="ml-0 mr-2 white--text font-weight-600"
                label
              >
                CONTRACT
              </v-chip>
            </template>
            <span>Contract</span>
          </v-tooltip>
        </template>
        <template v-if="detail.revise">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="teal lighten-4"
                class="ml-0 mr-2 black--text font-weight-600"
                label
                link
              >
                REVISION
              </v-chip>
            </template>
            <span>Revision of {{ detail.revise.barcode }}</span>
          </v-tooltip>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <div class="d-flex align-center justify-end" v-if="pageLoaded">
        <template v-if="detail.status != 2 && detail.status == 3">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('revise')"
              >
                <v-icon left>mdi-update</v-icon> Revise
              </v-btn>
            </template>
            <span>Click here to revise Quote</span>
          </v-tooltip>
        </template>
        <template
          v-if="
            detail.status != 2 &&
            detail.status != 6 &&
            detail.status != 3 &&
            detail.status != 7
          "
        >
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="statusLoading"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="updateMoreAction('edit')"
              >
                <v-icon left>mdi-pencil</v-icon> Edit
              </v-btn>
            </template>
            <span>Click here to edit Quote</span>
          </v-tooltip>
        </template>
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="statusLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="
          max-height: calc(100vh - 185px);
          height: calc(100vh - 185px);
          position: relative;
        "
      >
        <v-container fluid>
          <v-row v-if="pageLoaded">
            <v-col md="5">
              <span class="color-custom-blue font-weight-700 font-size-19">
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: detail.customer.id,
                      },
                    })
                  "
                  >{{ detail.customer.display_name }}
                </router-link>
              </span>
              <v-layout class="my-4">
                <v-flex md6 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Service Location</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                          >{{ detail.property.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.property_person.primary_email }}
                        <v-icon
                          v-if="detail.property_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Billing Address</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                          >{{ detail.billing.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ detail.billing_person.primary_email }}
                        <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-col>

            <v-col md="7" class="custom-border-left gray-background">
              <table width="100%">
                <tr>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    colspan="2"
                  >
                    <span
                      class="color-custom-blue font-weight-700 font-size-19"
                      >{{ detail.job_title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Quotation #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.barcode }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Rate opportunity
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <v-rating
                      readonly
                      dense
                      v-model.trim="detail.rating"
                      background-color="orange lighten-3"
                      color="orange"
                    ></v-rating>
                  </td>
                </tr>
                <tr v-if="false">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Reference #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.reference }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Sales representatives
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.sales }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Prepared by
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.prepared_by }}</label>
                  </td>
                </tr>
                <tr v-if="detail">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Customer Reference
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.customer_referance }}</label>
                  </td>
                </tr>
                <tr v-if="detail">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Term
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.quotation_term }}</label>
                  </td>
                </tr>

                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Remark 
                  </td>
                  
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.remark }}</label>
                  </td>
                </tr> -->
                <tr>
                  <!--  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Is Contract ?
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{
                      detail.is_contract ? "Yes" : "No"
                    }}</label>
                  </td> -->
                </tr>
                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Contract Duration
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0"
                      >{{ formatDate(detail.contract_start) }} to
                      {{ formatDate(detail.contract_end) }} ({{
                        detail.duration_value
                      }}
                      {{ detail.duration_type }})</label
                    >
                  </td>
                </tr>
                <tr v-if="detail.is_contract">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    No. of Services
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.total_service }}</label>
                  </td>
                </tr>
              </table>
              <hr />
              <table width="100%">
                <tr>
                  <td
                    width="33.33%"
                    valign="middle"
                    class="py-1 font-size-18 font-weight-600"
                  >
                    Quotation Date
                  </td>
                  <td
                    width="33.33%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-600"
                  >
                    Quotation Open Till
                  </td>
                  <td
                    width="33.33%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-600"
                  >
                    Quotation Sent Date
                  </td>
                </tr>
                <tr>
                  <td
                    width="33.33%"
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                  >
                    {{ formatDate(detail.date) }}
                  </td>
                  <td
                    width="33.33%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-500"
                  >
                    {{ formatDate(detail.open_till) }}
                  </td>
                  <td
                    width="33.33%"
                    valign="middle"
                    class="custom-border-left px-4 py-1 font-size-18 font-weight-500"
                  >
                    {{ formatDate(detail.sent_date) }}
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12">
              <v-tabs
                active-class="custom-tab-active"
                v-model="quotationTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Items
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#invoice"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Invoices
                </v-tab>
                <v-tab class="font-size-16 font-weight-600 px-8" href="#visit">
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Visits
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="quotationTab">
                <v-tab-item value="other">
                  <v-container fluid>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Description</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.description || '<em>No Description</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >TERMS &amp; CONDITIONS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >INTERNAL NOTES &amp; ATTACHMENTS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <v-layout class="mb-4">
                          <v-flex md6 class="custom-border-right">
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.client_remark ||
                                '<em>No Client Notes</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex>
                          <v-flex md6>
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.admin_remark || '<em>No Admin Notes</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex>
                        </v-layout>
                        <v-layout
                          v-if="detail.documents.length"
                          class="custom-border-top"
                        >
                          <v-flex md12>
                            <FileTemplate
                              :attachments="detail.documents"
                            ></FileTemplate>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item value="line-item">
                  <QuotationLineItem
                    show-detail
                    :db-line-items="dbLineItems"
                    :db-equipments="dbEquipments"
                    :discount-value="detail.discount_value"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :tax-value="detail.tax_value"
                    :quotation-status="detail.status"
                    :adjustment-value="detail.adjustment"
                    v-on:load:content="getQuotation()"
                  ></QuotationLineItem>
                </v-tab-item>
                <v-tab-item value="invoice">
                  <InternalInvoice
                    type="quotation"
                    :type-id="quotationId"
                  ></InternalInvoice>
                </v-tab-item>
                <v-tab-item value="visit">
                  <InternalVisit
                    is-admin
                    visit-type="all"
                    internal
                    :quotation-id="detail.id"
                    :customer-id="detail.customer.id"
                    :entity-id="detail.entity_id"
                  ></InternalVisit>
                </v-tab-item>

                <v-tab-item
                  v-if="getPermission('history:view')"
                  :value="'history'"
                >
                  <InternalHistoryDetail
                    type="quotation"
                    :type_id="detail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
                <v-tab-item value="history"></v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="quotation"
        v-on:close="mail_dialog = false"
        :type-id="quotationId"
        v-on:success="getQuotation()"
      ></SendMail>
      <template>
        <ManageRemark
          :dialog="manageBrandDialog"
          :e_designation="engineerDesignations"
          v-on:close-dialog="manageBrandDialog = false"
          v-on:refresh="getQuotation"
          :type-id="quotationId"
        ></ManageRemark>
      </template>
      <template>
        <ManageAcceptRemark
          :dialog="manageAceeptBrandDialog"
          :e_designation="engineerDesignations"
          v-on:close-dialog="manageAceeptBrandDialog = false"
          v-on:refresh="getQuotation"
          :type-id="quotationId"
        ></ManageAcceptRemark>
      </template>
    </template>
  </DetailTemplate>
</template>
<script>
import ManageRemark from "@/view/pages/partials/Manage-Remark.vue";
import ManageAcceptRemark from "@/view/pages/partials/Manage-Accept-Remark.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import QuotationLineItem from "@/view/pages/partials/Line-Item-Detail-New";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import JwtService from "@/core/services/jwt.service";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      quotationId: 0,
      pageLoaded: false,
      statusLoading: false,
      mail_dialog: false,
      manageBrandDialog: false,
      manageAceeptBrandDialog: false,
      invoiceLoading: false,
      quotationTab: "other",
      moreActions: [],
      detail: {},
      dbLineItems: [],
      dbEquipments: [],
    };
  },
  methods: {
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          if (data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                radio_cu_au_value: data[i].radio_cu_au_value,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                product_type: data[i].product_type,
                to_equipment: data[i].to_equipment,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
                cu: data[i].cu,
                fcu: data[i].fcu,
              });
            }

            _this.$nextTick(() => {
              _this.dbLineItems = _this.lodash.filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.dbEquipments = _this.lodash.filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateMoreAction(type) {
      switch (type) {
        case "edit":
          this.editQuotation();
          break;
        case "revise":
          this.reviseQuotation();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.manageCancelled();
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          //  this.manageAceeptBrandDialog = true;
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    manageCancelled() {
      if (this.detail && this.detail.status == 2) {
        this.manageBrandDialog = true;
      } else {
        this.updateStatus("mark_as_cancelled");
      }
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.quotationId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateStatus(status) {
      if (this.statusLoading) {
        return false;
      }
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "quotation/" + this.quotationId + "/status",
          data: { status },
        })
        .then(() => {
          this.getQuotation();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },
    downloadPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/pdf/download?token=${token}`;
      window.open(url, "_blank");
    },
    printPDF() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation/${id}/print?token=${token}`;
      window.open(url, "_blank");
    },
    routeToJob() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            quotation: this.quotationId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            quotation: this.quotationId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToDuplicate() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            duplicate: this.quotationId,
          },
        })
      );
    },
    reviseQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            revise: this.quotationId,
          },
        })
      );
    },
    editQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.update", {
          params: {
            id: this.quotationId,
          },
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
          },
        })
      );
    },
    getQuotation() {
      this.$store
        .dispatch(GET, { url: "quotation/" + this.quotationId })
        .then(({ data }) => {
          this.detail = data;

          this.moreActions = data.more_actions;

          this.getLineItems({
            quotation: this.detail.id,
          });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
  },
  created() {
    this.quotationId = this.lodash.toSafeInteger(this.$route.params.id);
  },
  mounted() {
    this.getQuotation();
  },
  components: {
    CustomStatus,
    FileTemplate,
    DetailTemplate,
    InternalVisit,
    SendMail,
    InternalInvoice,
    QuotationLineItem,
    InternalHistoryDetail,
    ManageRemark,
    ManageAcceptRemark,
  },
};
</script>
