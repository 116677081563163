import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CustomerMixin from "@/core/plugins/customer-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import DialogPropertyAddress from "@/view/pages/customer/create-or-update/Property-Address.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
export default {
  mixins: [CustomerMixin],
  data: function data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      customerProperty: null,
      timeoutLimit: 500,
      timeout: null,
      propertyAddress: new Object(),
      createPropertyDialog: false,
      customerPropertyList: new Array()
    };
  },
  watch: {
    customerProperty: function customerProperty(param) {
      if (!param) {
        var _this = this;

        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchCustomerProperty();
        }, _this.timeoutLimit);
      }
    },
    customer: function customer() {
      this.searchCustomerProperty(null);
    }
  },
  props: {
    disabledAutoSelect: {
      type: Boolean,
      default: false
    },
    customerPropertyDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 768
    },
    customer: {
      type: Number,
      required: true
    }
  },
  components: {
    Dialog: Dialog,
    Barcode: Barcode,
    DialogPropertyAddress: DialogPropertyAddress
  },
  methods: {
    onEsc: function onEsc() {
      this.resetSearch();
    },
    onEnter: function onEnter() {
      if (this.isSearching) {
        this.searchCustomerProperty();
      }
    },
    onTab: function onTab() {
      if (this.isSearching) {
        this.searchCustomerProperty();
      }
    },
    resetSearch: function resetSearch() {
      var _this = this;

      _this.$nextTick(function () {
        _this.customerProperty = null;
        _this.isFocused = false;
      });
    },
    searchCustomerProperty: function searchCustomerProperty() {
      var _this = this;

      _this.searchLoader = true;

      _this.getCustomerProperties(_this.customer, _this.customerProperty).then(function (response) {
        _this.customerPropertyList = response;

        if (!_this.disabledAutoSelect && _this.customerPropertyList.length === 1) {
          _this.$emit("selectCustomerProperty", _this.customerPropertyList[0].id);
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.createPropertyDialog = false;
        _this.searchLoader = false;
      });
    },
    selectCustomerProperty: function selectCustomerProperty(param) {
      this.$emit("selectCustomerProperty", param.id);
    },
    getFormattedProperty: function getFormattedProperty(row) {
      var tempArr = [];

      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }

      if (row.street_1) {
        tempArr.push(row.street_1);
      }

      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }

      if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }

      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }

      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country.name);
      }

      return tempArr.join(", ");
    },
    updateProperty: function updateProperty(param) {
      this.propertyAddress = param;
    },
    createProperty: function createProperty() {
      var _this = this;

      if (!_this.$refs.createProperty.validate()) {
        return false;
      }

      var addressFormData = new Array();
      addressFormData.push(_this.propertyAddress);

      var formData = _this.lodash.pickBy(addressFormData, _this.lodash.identity);

      if (_this.lodash.isEmpty(formData) === false) {
        _this.formLoading = true;

        _this.$store.dispatch(CLEAR_ERROR, {});

        _this.$store.dispatch(PUT, {
          url: "address/" + _this.customer,
          data: {
            address: formData
          }
        }).then(function () {
          _this.searchCustomerProperty();
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.formLoading = false;
        });
      }
    }
  },
  mounted: function mounted() {
    this.searchCustomerProperty();
    this.$refs["search-customer-property"].focus();
  },
  computed: {
    isSearching: function isSearching() {
      return this.customerProperty && this.customerProperty.length > 0;
    },
    placeholder: function placeholder() {
      return this.isFocused ? "Start Typing then press enter" : "Search Service Location...";
    }
  }
};