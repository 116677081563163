import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CustomerMixin from "@/core/plugins/customer-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { POST, CLEAR_ERROR } from "@/core/services/store/request.module";
import DialogContactDetails from "@/view/pages/customer/create-or-update/Contact-Details.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
export default {
  mixins: [CustomerMixin],
  data: function data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      customer: null,
      timeoutLimit: 500,
      timeout: null,
      customerArr: new Object(),
      createCustomerDialog: false,
      customerList: new Array()
    };
  },
  props: {
    customerDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 768
    },
    entity: {
      type: Number,
      default: 0
    }
  },
  components: {
    Dialog: Dialog,
    Barcode: Barcode,
    DialogContactDetails: DialogContactDetails
  },
  watch: {
    customer: function customer(param) {
      if (!param) {
        var _this = this;

        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchCustomer();
        }, _this.timeoutLimit);
      }
    }
  },
  methods: {
    onEsc: function onEsc() {
      this.resetSearch();
    },
    onEnter: function onEnter() {
      if (this.isSearching) {
        this.searchCustomer();
      }
    },
    onTab: function onTab() {
      if (this.isSearching) {
        this.searchCustomer();
      }
    },
    resetSearch: function resetSearch() {
      var _this = this;

      _this.$nextTick(function () {
        _this.customer = null;
        _this.isFocused = false;
      });
    },
    searchCustomer: function searchCustomer() {
      var _this = this;

      _this.searchLoader = true;

      _this.getCustomers(_this.customer, _this.entity).then(function (response) {
        _this.customerList = response;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.searchLoader = false;
        _this.createCustomerDialog = false;
      });
    },
    selectCustomer: function selectCustomer(param) {
      if (param.persons_count === 1 && this.lodash.isEmpty(param.single_person) === false) {
        this.$emit("selectCustomerPerson", param.single_person.id);
      }

      if (param.property_count === 1 && this.lodash.isEmpty(param.single_property) === false) {
        this.$emit("selectCustomerProperty", param.single_property.id);
      }

      if (param.billing_count === 1 && this.lodash.isEmpty(param.single_billing) === false) {
        this.$emit("selectCustomerBilling", param.single_billing.id);
      }

      this.$emit("selectCustomer", param.id);
    },
    getProfileImage: function getProfileImage(imagePath) {
      if (!imagePath) {
        return this.$filePath + "storage/uploads/images/no-image.png";
      }

      return this.$filePath + imagePath;
    },
    getDefaultPerson: function getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }

      return defaultPerson.display_name;
    },
    updateCustomerDetailOnly: function updateCustomerDetailOnly(param) {
      this.customerArr = param;
    },
    createCustomerOnly: function createCustomerOnly() {
      var _this = this;

      if (!_this.$refs.createCustomerOnly.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store.dispatch(CLEAR_ERROR, {});

      var formData = {
        title: _this.customerArr.salutation,
        display_name: _this.customerArr.display_name,
        company_name: _this.customerArr.company_name,
        profile_logo: _this.customerArr.profile_logo,
        first_name: _this.customerArr.first_name,
        last_name: _this.customerArr.last_name,
        group_id: _this.customerArr.group_id,
        primary: +_this.customerArr.primary,
        details: _this.customerArr.details
      };

      _this.$store.dispatch(POST, {
        url: "customer-only",
        data: formData
      }).then(function () {
        _this.searchCustomer();
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.formLoading = false;
      });
    }
  },
  mounted: function mounted() {
    this.searchCustomer();
    this.$refs["search-customer"].focus();
  },
  computed: {
    isSearching: function isSearching() {
      return this.customer && this.customer.length > 0;
    },
    placeholder: function placeholder() {
      return this.isFocused ? "Start Typing then press enter" : "Search Customer...";
    },
    getDefaultImage: function getDefaultImage() {
      return this.$filePath + "storage/uploads/images/no-image.png";
    }
  }
};