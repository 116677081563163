import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.to-string.js";
import moment from "moment-timezone";
import inflect from "i";
import AppConfiguration from "@/core/config/app.config";
import { POST } from "@/core/services/store/request.module";
import { GET_COUNTRY, GET_STATE, GET_CITY } from "@/core/services/store/common.module";
import LocalService from "@/core/services/local.service";
import accounting from "accounting-js";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

function getTitle(vm) {
  var _vm$$options = vm.$options,
      title = _vm$$options.title,
      name = _vm$$options.name;

  if (title && name) {
    title = inflect().titleize(name) + " | " + inflect().titleize(title);
  }

  if (!title && name) {
    title = inflect().titleize(name);
  }

  if (title) {
    return typeof title === "function" ? title.call(vm) : title;
  }
}

export default {
  methods: {
    isNotEmpty: function isNotEmpty(param) {
      return this.lodash.isEmpty(param) === false;
    },
    formatGPSAddress: function formatGPSAddress(address) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.$store.dispatch(POST, {
          url: "create-address-from-google",
          data: address
        }).then(function (response) {
          resolve(response.data);
        }).catch(function (error) {
          _this.logError(error);

          reject(error);
        });
      });
    },
    updateActiveField: function updateActiveField(param) {
      this.currentActiveField = param;
    },
    getCountries: function getCountries(param) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          var country = _this.lodash.toInteger(param);

          var countryArr = LocalService.getData("country-" + country);

          if (_this.lodash.isEmpty(countryArr) === true) {
            _this.$store.dispatch(GET_COUNTRY, country).then(function (response) {
              LocalService.saveData("country-" + country, response.data);
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            resolve(countryArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getStates: function getStates(param1, param2) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          var country = _this.lodash.toInteger(param1.id);

          var state = _this.lodash.toInteger(param2);

          var stateArr = LocalService.getData("state-" + country + "-" + state);

          if (_this.lodash.isEmpty(stateArr) === true) {
            _this.$store.dispatch(GET_STATE, country + "/" + state).then(function (response) {
              LocalService.saveData("state-" + country + "-" + state, response.data);
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            resolve(stateArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCities: function getCities(param1, param2) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        try {
          var state = _this.lodash.toInteger(param1.id);

          var city = _this.lodash.toInteger(param2);

          var cityArr = LocalService.getData("city-" + state + "-" + city);

          if (_this.lodash.isEmpty(cityArr) === true) {
            _this.$store.dispatch(GET_CITY, state + "/" + city).then(function (response) {
              LocalService.saveData("city-" + state + "-" + city, response.data);
              resolve(response.data);
            }).catch(function (error) {
              reject(error);
            });
          } else {
            resolve(cityArr);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    formatDate: function formatDate(date) {
      if (!date) {
        return null;
      }

      var Config = AppConfiguration.get();

      if (Config) {
        return moment(date).format(Config.dateFormat);
      }

      return date;
    },
    formatDateTime: function formatDateTime(date) {
      if (!date) {
        return null;
      }

      var Config = AppConfiguration.get();

      if (Config) {
        return moment(date).format(Config.dateTimeFormat);
      }

      return date;
    },
    formatNumber: function formatNumber(number) {
      return accounting.formatNumber(number);
    },
    formatMoney: function formatMoney(number) {
      return accounting.formatMoney(number, this.CurrencySymbol);
    },
    accountingUnFormat: function accountingUnFormat(number) {
      return accounting.unformat(number);
    },
    accountingToFixed: function accountingToFixed(number) {
      return accounting.toFixed(number);
    },
    booleanToString: function booleanToString(param) {
      switch (param) {
        case true:
          return "Yes";

        case false:
          return "No";

        default:
          "No";
      }
    },
    commitErrors: function commitErrors(response) {
      var requestErrors = new Array();

      if (response && response.status === 422) {
        for (var error in response.data) {
          if (response.data[error]) {
            for (var i = response.data[error].length - 1; i >= 0; i--) {
              requestErrors.push(response.data[error][i]);
            }
          }
        }
      } else {
        requestErrors.push(response.toString());
      }

      return requestErrors;
    },
    updatePageTitle: function updatePageTitle() {
      var title = getTitle(this);

      if (title) {
        document.title = title;
      }
    },
    goBack: function goBack() {
      this.$router.go(-1);
    }
  },
  created: function created() {
    var Config = AppConfiguration.get();

    if (Config) {
      this.CurrencySymbol = Config.currency.symbol;
    }
  }
};