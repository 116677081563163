import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CustomerMixin from "@/core/plugins/customer-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import DialogContactPersons from "@/view/pages/customer/create-or-update/Contact-Persons.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
export default {
  mixins: [CustomerMixin],
  data: function data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      contactPerson: null,
      timeoutLimit: 500,
      timeout: null,
      personFormData: new Object(),
      createPersonDialog: false,
      customerPersonList: new Array()
    };
  },
  watch: {
    contactPerson: function contactPerson(param) {
      if (!param) {
        var _this = this;

        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchCustomerPerson();
        }, _this.timeoutLimit);
      }
    },
    customer: function customer() {
      this.searchCustomerPerson();
    }
  },
  components: {
    Dialog: Dialog,
    Barcode: Barcode,
    DialogContactPersons: DialogContactPersons
  },
  props: {
    disabledAutoSelect: {
      type: Boolean,
      default: false
    },
    customerPersonDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 768
    },
    customer: {
      type: Number,
      required: true
    }
  },
  methods: {
    onEsc: function onEsc() {
      this.resetSearch();
    },
    onEnter: function onEnter() {
      if (this.isSearching) {
        this.searchCustomerPerson();
      }
    },
    onTab: function onTab() {
      if (this.isSearching) {
        this.searchCustomerPerson();
      }
    },
    resetSearch: function resetSearch() {
      var _this = this;

      _this.$nextTick(function () {
        _this.contactPerson = null;
        _this.isFocused = false;
      });
    },
    searchCustomerPerson: function searchCustomerPerson() {
      var _this = this;

      _this.searchLoader = true;

      _this.getCustomerPersons(_this.customer, _this.contactPerson).then(function (response) {
        _this.customerPersonList = response;

        if (!_this.disabledAutoSelect && _this.customerPersonList.length === 1) {
          _this.selectCustomerPerson(_this.customerPersonList[0]);
        }
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.createPersonDialog = false;
        _this.searchLoader = false;
      });
    },
    selectCustomerPerson: function selectCustomerPerson(param) {
      this.$emit("selectCustomerPerson", param.id);
    },
    updateContactPerson: function updateContactPerson(param) {
      this.personFormData = param;
    },
    createPerson: function createPerson() {
      var _this = this;

      if (!_this.$refs.createPerson.validate()) {
        return false;
      }

      if (_this.lodash.isEmpty(_this.personFormData) === false) {
        _this.formLoading = true;

        _this.$store.dispatch(CLEAR_ERROR, {});

        _this.$store.dispatch(PUT, {
          url: "customer/" + _this.customer + "/contact-person",
          data: {
            persons: _this.personFormData
          }
        }).then(function () {
          _this.searchCustomerPerson();
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.formLoading = false;
        });
      }
    }
  },
  mounted: function mounted() {
    this.searchCustomerPerson();
    this.$refs["search-customer-person"].focus();
  },
  computed: {
    isSearching: function isSearching() {
      return this.contactPerson && this.contactPerson.length > 0;
    },
    placeholder: function placeholder() {
      return this.isFocused ? "Start Typing then press enter" : "Search Contact Person...";
    }
  }
};