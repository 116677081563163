import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      editorReady: false,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        toolbar: ["heading", "|", "bold", "italic", "|", "outdent", "indent", "|", "bulletedList", "numberedList", "blockQuote", "|", "undo", "redo"]
      }
    };
  },
  watch: {
    value: function value() {
      this.init();
    }
  },
  methods: {
    onInput: function onInput(value) {
      this.$emit("input", value);
    },
    init: function init() {
      this.editorData = this.lodash.toString(this.value);
    }
  },
  mounted: function mounted() {
    this.init();
  },
  components: {
    ckeditor: CKEditor.component
  }
};