<template>
  <Dialog :commonDialog="dialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex> Manage Quotation Remark </v-flex>
        <!-- <v-flex class="text-right">
          <v-btn small class="mx-4" v-on:click="createStaffDesignation">
            <span class="font-size-16 font-weight-600">Add Remark</span>
          </v-btn>
        </v-flex> -->
      </v-layout>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <v-container class="py-0">
          <v-row class="py-0">
            <v-col md="12" class="py-0">
              <table class="width-100">
                <tbody>
                  <tr>
                    <td>
                      <v-text-field
                        v-model.trim="remark"
                        dense
                        filled
                        label="Remark"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <!-- <td width="50" v-if="eDesignation.length > 1">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteContractType(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading"
        :loading="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateContractSetting"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT } from "@/core/services/store/request.module";
//import {cloneDeep} from "lodash";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },

    typeId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    remark: {
      deep: true,
      immediate: true,
      handler(param) {
        this.qremark = this.lodash.cloneDeep(param);
      },
    },
  },
  data: () => {
    return {
      pageLoading: false,
      remark: null,
    };
  },
  methods: {
    updateContractSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "quotation/remark/" + this.typeId,
          data: {
            remark: this.remark,
          },
        })
        .then(() => {
          _this.$emit("close-dialog", true);
          _this.$emit("refresh", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    /*  createStaffDesignation() {
      this.eDesignation.push({
        value: null,
      });
    }, */
    /*  deleteContractType(index) {
      if (this.eDesignation.length > 1) {
        this.eDesignation.splice(index, 1);
      }
    }, */
  },
  components: {
    Dialog,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
