import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
export default {
  mixins: [FileManagerMixin, ValidationMixin],
  props: {
    attachments: {
      type: Array,
      default: function _default() {
        return new Array();
      }
    },
    allowDelete: {
      type: Boolean,
      default: true
    },
    onlyImage: {
      type: Boolean,
      default: false
    },
    allowDownload: {
      type: Boolean,
      default: true
    },
    allowUpload: {
      type: Boolean,
      default: false
    },
    isMinDisplay: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isUploading: false,
      documentArray: []
    };
  },
  watch: {
    documentArray: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.updateFiles(param);
      }
    },
    attachments: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        var a = this.lodash.cloneDeep(this.attachments);
        var b = this.lodash.cloneDeep(this.documentArray);

        if (this.lodash.isEqual(a, b) === false) {
          this.documentArray = param;
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.lodash.isEmpty(this.attachments) === false) {
      this.documentArray = this.attachments;
    }
  },
  methods: {
    isValidImage: function isValidImage(file_url) {
      try {
        var _this = this;

        var validImageExtension = ["png", "jpg", "jpeg"];

        var fileExtention = _this.lodash.last(file_url.split("."));

        return _this.lodash.includes(validImageExtension, fileExtention.toLowerCase());
      } catch (error) {
        this.logError(error);
        return false;
      }
    },
    updateFiles: function updateFiles(param) {
      var filesArray = [];

      for (var i = 0; i < param.length; i++) {
        if (this.lodash.isEmpty(param[i]) === false) {
          filesArray.push(param[i].id);
        }
      }

      if (this.lodash.isEmpty(filesArray) === false) {
        this.$emit("file:updated", filesArray);
      }
    },
    uploadFile: function uploadFile(file) {
      var _this2 = this;

      this.isUploading = true;
      this.uploadAttachment(file).then(function (response) {
        for (var i = response.length - 1; i >= 0; i--) {
          _this2.documentArray.push(response[i]);
        }
      }).catch(function (error) {
        _this2.logError(error);
      }).finally(function () {
        _this2.isUploading = false;
      });
    }
  },
  computed: {
    isActive: function isActive() {
      return this.allowUpload || this.lodash.isEmpty(this.documentArray) === false;
    }
  }
};