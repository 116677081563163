import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    visitList: {
      type: Boolean,
      default: false
    },
    priority: {
      type: Number,
      required: true
    }
  },
  computed: {
    priorityText: function priorityText() {
      if (this.priority == 1) {
        return "High";
      }

      if (this.priority == 2) {
        return "Medium";
      }

      if (this.priority == 3) {
        return "Low";
      }

      if (this.priority == 0) {
        return "None";
      }

      return null;
    },
    priorityColor: function priorityColor() {
      if (this.priority == 1) {
        return "red darken-3";
      }

      if (this.priority == 2) {
        return "orange darken-4";
      }

      if (this.priority == 3) {
        return "light-green darken-3";
      }

      if (this.priority == 0) {
        return "cyan";
      }

      return null;
    }
  }
};