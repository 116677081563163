import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { WarrantyEventBus } from "@/core/lib/warranty/warranty.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import moment from "moment-timezone";
import { toSafeInteger, isEmpty, isArray } from "lodash";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "manage-warranty",
  model: {
    prop: "warranty",
    event: "input"
  },
  props: {
    warranty: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    warranty: {
      deep: true,
      handler: function handler() {
        this.init(false);
      }
    }
  },
  data: function data() {
    return {
      formValid: true,
      warrantyDialog: false,
      warrantyOption: {
        status: false,
        start_date: moment().format("YYYY-MM-DD"),
        unique_id: null,
        description: null
      },
      defaultItemWarranty: [],
      warrantyDurationTypes: [{
        text: "Day(s)",
        value: "day"
      }, {
        text: "Week(s)",
        value: "week"
      }, {
        text: "Month(s)",
        value: "month"
      }, {
        text: "Year(s)",
        value: "year"
      }]
    };
  },
  methods: {
    manageWarranty: function manageWarranty() {
      var _this = this;

      this.init(false);
      this.$nextTick(function () {
        _this.warrantyDialog = true;
      });
    },
    init: function init(status) {
      var warranty = this.warranty;

      if (warranty && !isEmpty(warranty)) {
        this.warrantyOption.status = status ? true : warranty.status;
        this.warrantyOption.start_date = warranty.start_date;
        this.warrantyOption.unique_id = warranty.unique_id;
        this.warrantyOption.description = warranty.description;
        this.defaultItemWarranty = [];

        if (warranty.duration && isArray(warranty.duration) && warranty.duration.length) {
          for (var z = 0; z < warranty.duration.length; z++) {
            this.defaultItemWarranty.push({
              value: warranty.duration[z].value,
              field: warranty.duration[z].field
            });
          }
        }
      }
    },
    initDialog: function initDialog(status) {
      var _this2 = this;

      if (status) {
        this.init(true);
        this.$nextTick(function () {
          _this2.warrantyDialog = true;
        });
      } else {
        this.voidWarranty();
      }
    },
    removeWarranty: function removeWarranty(index) {
      if (this.readonly) {
        return false;
      }

      this.defaultItemWarranty.splice(index, 1);
    },
    voidWarranty: function voidWarranty() {
      if (this.readonly) {
        return false;
      }

      this.warrantyDialog = false;
      this.warrantyOption.status = false;
      this.emitInput();
    },
    emitInput: function emitInput() {
      var _this3 = this;

      if (this.readonly) {
        return false;
      }

      this.$nextTick(function () {
        var warranty = {
          status: _this3.warrantyOption.status,
          start_date: _this3.warrantyOption.start_date,
          unique_id: _this3.warrantyOption.unique_id,
          description: _this3.warrantyOption.description,
          duration: _this3.defaultItemWarranty
        };

        _this3.$emit("input", warranty);

        _this3.$emit("update:warranty", warranty);
      });
    },
    pushWarranty: function pushWarranty() {
      if (this.readonly) {
        return false;
      }

      if (this.defaultItemWarranty.length < this.warrantyDurationTypes.length) {
        this.defaultItemWarranty.push({
          value: null,
          field: null
        });
      }
    },
    updateWarranty: function updateWarranty() {
      if (this.readonly) {
        return false;
      }

      if (!this.$refs.warrantyForm.validate()) {
        return false;
      }

      this.emitInput();
      this.$emit("change", true);
      this.warrantyDialog = false;
    }
  },
  components: {
    Dialog: Dialog,
    Datepicker: Datepicker
  },
  mounted: function mounted() {
    this.init(false);
  },
  computed: {
    dialogWidth: function dialogWidth() {
      return toSafeInteger(document.body.clientWidth / 100 * 60);
    }
  }
};