import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET } from "@/core/services/store/request.module"; // import { getConfig } from "@/core/services/local.service";

export default {
  data: function data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: false,
      entity: null,
      timeoutLimit: 500,
      timeout: null,
      entityList: new Array()
    };
  },
  props: {
    entityDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 768
    }
  },
  watch: {
    entity: function entity(param) {
      if (!param) {
        var _this = this;

        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchEntity();
        }, _this.timeoutLimit);
      }
    }
  },
  methods: {
    onEsc: function onEsc() {
      this.resetSearch();
    },
    onEnter: function onEnter() {
      if (this.isSearching) {
        this.searchEntity();
      }
    },
    onTab: function onTab() {
      if (this.isSearching) {
        this.searchEntity();
      }
    },
    resetSearch: function resetSearch() {
      var _this = this;

      _this.$nextTick(function () {
        _this.entity = null;
        _this.isFocused = false;
      });
    },
    searchEntity: function searchEntity() {
      var _this = this;

      _this.searchLoader = true;

      _this.$store.dispatch(GET, {
        url: "setting/entity"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.entityList = data;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.searchLoader = false;
      });
    },
    selectEntity: function selectEntity(param) {
      this.$emit("selectEntity", param.id);
    }
  },
  mounted: function mounted() {
    // this.entityList = getConfig('entity');
    this.searchEntity(); // this.$refs["search-entity"].focus();
  },
  computed: {
    isSearching: function isSearching() {
      return this.entity && this.entity.length > 0;
    },
    placeholder: function placeholder() {
      return this.isFocused ? "Start Typing then press enter" : "Search Entity...";
    }
  }
};