import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
export default {
  mixins: [CommonMixin],
  data: function data() {
    return {
      dates: [],
      pageLoading: false,
      histories: new Array(),
      filter: {
        search: null
      },
      per_page: 10,
      current_page: 1,
      offset: 0,
      total_pages: 0,
      total_rows: 0
    };
  },
  components: {
    ListingFooter: ListingFooter
  },
  props: {
    type: {
      required: true,
      type: String,
      default: ""
    },
    type_id: {
      required: true,
      type: Number,
      default: 0
    }
  },
  watch: {
    type_id: function type_id() {
      var type_id = this.lodash.toSafeInteger(this.type_id);

      if (type_id > 0) {
        this.getHistories();
      }
    }
  },
  methods: {
    clearFilter: function clearFilter(field) {
      var _this = this;

      switch (field) {
        case "search":
          _this.filter.search = null;
          break;

        case "dates":
          _this.dates = [];
          break;
      }

      _this.getHistories();
    },
    getHistories: function getHistories() {
      var _this = this;

      _this.pageLoading = true;
      _this.histories = [];

      _this.$store.dispatch(QUERY, {
        url: "history",
        data: {
          type: _this.type,
          type_id: _this.type_id,
          per_page: _this.per_page,
          current_page: _this.current_page,
          search: _this.filter.search,
          start_date: _this.dates[0] || null,
          end_date: _this.dates[1] || null
        }
      }).then(function (_ref) {
        var data = _ref.data;
        _this.offset = data.offset;
        _this.histories = data.rows;
        _this.total_pages = Math.ceil(data.total_rows / data.per_page);
        _this.current_page = data.current_page;
        _this.total_rows = data.total_rows;
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    PaginationEventBus.$off("update:pagination");
  },
  mounted: function mounted() {
    var _this = this;

    var type_id = _this.lodash.toSafeInteger(_this.type_id);

    if (type_id > 0) {
      _this.getHistories();
    }

    PaginationEventBus.$on("update:pagination", function (param) {
      _this.current_page = param;

      _this.getHistories();
    });
  },
  computed: {
    showingFrom: function showingFrom() {
      return this.lodash.isEmpty(this.histories) === false ? this.offset + 1 : 0;
    },
    showingTo: function showingTo() {
      return this.offset + (this.histories ? this.histories.length : 0);
    }
  }
};