//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    data: {
      type: Object,
      default: function _default() {
        return {
          added_by: null,
          created_at: null,
          updated_by: null,
          modified_at: null
        };
      }
    }
  }
};