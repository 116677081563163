//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AppConfiguration from "@/core/config/app.config";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  data: function data() {
    return {
      pickerType: null,
      startDatePicker: false,
      datePicker: {
        date: new Date().toISOString().substr(0, 10)
      }
    };
  },
  props: {
    defaultDate: {
      type: String,
      default: function _default() {
        return new Date().toISOString().substr(0, 10);
      }
    },
    placeholder: {
      type: String,
      default: function _default() {
        return "Date";
      }
    },
    customClass: {
      type: String,
      default: ""
    },
    activePicker: {
      type: String,
      default: "DATE"
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    pageLoading: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    nullable: {
      type: Boolean,
      default: false
    },
    removeBorder: {
      type: Boolean,
      default: false
    }
  },
  mounted: function mounted() {
    if (!this.nullable) {
      this.datePicker.date = this.defaultDate || "";
      this.$emit("update:date-picker", this.datePicker.date);
      this.$emit("input", this.datePicker.date);
    } else {
      this.datePicker.date = "";
    }
  },
  methods: {
    saveDate: function saveDate(date) {
      var _this2 = this;

      this.$refs.datePicker.save(date);
      this.$nextTick(function () {
        _this2.$emit("change", true);
      });
    }
  },
  watch: {
    startDatePicker: function startDatePicker(val) {
      var _this = this;

      val && setTimeout(function () {
        return _this.pickerType = _this.activePicker;
      });
    },
    defaultDate: function defaultDate() {
      if (!this.nullable) {
        this.datePicker.date = this.defaultDate || "";
      }
    },
    nullable: function nullable(param) {
      if (param) {
        this.datePicker.date = "";
      }
    },
    datePicker: {
      deep: true,
      immediate: true,
      handler: function handler(param) {
        this.$emit("update:date-picker", param.date);
        this.$emit("input", param.date);
      }
    }
  },
  computed: {
    fieldClass: function fieldClass() {
      var result = this.customClass;

      if (this.removeBorder) {
        result = result + "remove-input-border";
      }

      return result;
    },
    formattedStartDate: function formattedStartDate() {
      var Config = AppConfiguration.get();

      if (Config) {
        if (!this.datePicker.date) {
          if (this.nullable) {
            return null;
          }

          return moment().format(Config.dateFormat);
        }

        return moment(this.datePicker.date).format(Config.dateFormat);
      }

      return this.datePicker.date;
    }
  }
};