import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    dataLoading: {
      type: Boolean
    },
    rowData: {
      type: Array
    },
    columnCount: {
      type: Number,
      required: true
    }
  }
};