//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    deleteDialog: {
      type: Boolean,
      required: true,
      default: false
    }
  }
};