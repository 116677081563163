import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { GET } from "@/core/services/store/request.module";
import LocalService from "@/core/services/local.service";
export default {
  data: function data() {
    return {
      pageLoading: true,
      statusList: {},
      param: {
        color: null,
        textcolor: null,
        text: null
      }
    };
  },
  props: {
    small: {
      type: Boolean,
      default: false
    },
    status: {
      type: Number,
      default: 0
    },
    endpoint: {
      type: String,
      required: true,
      default: ""
    }
  },
  watch: {
    status: {
      deep: true,
      handler: function handler() {
        this.assignStatusList();
      }
    }
  },
  methods: {
    get_status_list: function get_status_list() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        if (_this.endpoint) {
          var cache_response = LocalService.getData(_this.endpoint);

          if (_this.lodash.isEmpty(cache_response) === false) {
            resolve(cache_response);
          } else {
            _this.$store.dispatch(GET, {
              url: _this.endpoint
            }).then(function (response) {
              resolve(response.data);
              LocalService.saveData(_this.endpoint, response.data);
              console.log(response.data);
            }).catch(function (error) {
              reject(error);
            });
          }
        } else {
          resolve([]);
        }
      });
    },
    assignStatusList: function assignStatusList() {
      var _this = this;

      _this.get_status_list().then(function (response) {
        if (_this.lodash.isEmpty(response) === false) {
          _this.setParams(response).then(function (statusList) {
            _this.param = statusList[_this.status];
          }).finally(function () {
            _this.pageLoading = false;
          });
        }
      });
    },
    setParams: function setParams(response) {
      var _this = this;

      return new Promise(function (resolve) {
        for (var i = response.length - 1; i >= 0; i--) {
          _this.statusList[response[i].status] = response[i];

          if (i === 0) {
            resolve(_this.statusList);
          }
        }
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.param = {
      color: null,
      textcolor: null,
      text: null
    };
  },
  mounted: function mounted() {
    this.assignStatusList();
  }
};